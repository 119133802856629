





























































































































































import Vue from 'vue'
import { IReport, Report } from '@/shared/model/report.model'
import { downloadFileURL } from '@/shared/helpers'

export default Vue.extend({
  props: {
    report: {
      type: Object,
      default: () => new Report(),
    },
  },
  methods: {
    reportIsValid (report: IReport) {
      let valid = true
      if (report.questions) {
        for (const q of report.questions) {
          if (!q.valid) {
            valid = false
          }
        }
      }

      return valid
    },
    gotoprint () {
      if (this.report && this.report.pdfFile) {
        downloadFileURL(`/api/file/${this.report.pdfFile}`, 'Pruefbericht', 'application/pdf')
      }
    },
  },
})
